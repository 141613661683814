import {Button, Empty, Modal} from "antd";
import SimpleMotion from "../components/assist/simpleMotion/SimpleMotion";
import Title from "./Title";
import { Link } from "react-router-dom";
import Arrow from "../components/assist/arrow/Arrow";
import { postServicePublic } from "../tools";
import { jobadsUrl } from "../serviceUrls";
import { useEffect, useState } from "react";
import {ReloadOutlined} from "@ant-design/icons";

const JobAds = () => {
  const [data, setData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false); // Modal visibility state

  useEffect(() => {
    postServicePublic(jobadsUrl.getJobAdsActive, {}).then((res) => {
      //   console.log(res);
      setData(res.result);
    });
  }, []);

  const showModal = () => {
    setIsModalVisible(true);
  };

  // Function to handle the closing of the modal
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
      <div>
        <SimpleMotion>
          <div className="bg-white max-w-[1280px] mx-auto mt-4">
            <Title name="Нээлттэй ажлын байр"/>

                <div className="grid grid-cols-3 max-md:grid-cols-2 max-sm:grid-cols-1 text-brand-50">
                  {data.map((e, i) => (
                      <div
                          onClick={showModal}
                          className="py-6 px-9 border cursor-pointer hover:border-brand-50 group"
                      >
                        <div className="flex justify-between text-xl font-semibold mb-2">
                          <div className="w-11/12">{e.title}</div>
                          <div className="group-hover:translate-x-4 duration-300 flex items-center pr-6">
                            <Arrow/>
                          </div>
                        </div>
                        <div className="text-sm text-justify leading-5 font-light">
                          {e.description}
                        </div>
                      </div>
                  ))}
                </div>
          </div>
        </SimpleMotion>

        <Modal
            title={null}
            visible={isModalVisible}
            footer={null}
            onCancel={handleCancel}
            centered
            bodyStyle={{
              padding: 0,
              textAlign: "center",
            }}
        >
          <div
              style={{
                textAlign: "center",
                color: "#4d4d4d",
                padding: "0 40px 40px 40px",
                borderRadius: "10px",
                maxWidth: "400px",
                margin: "0 auto",
              }}
          >
            <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "20px",
                }}
            >
              <iframe
                  src="https://lottie.host/embed/4058725b-f5be-41a7-b6ab-9b0675b07ec5/L02A8zOMu4.lottie"
                  style={{
                    width: "150px",
                    height: "150px",
                    border: "none",
                    borderRadius: "10px",
                  }}
              ></iframe>
            </div>
            <div
                style={{
                  fontSize: "22px",
                  fontWeight: "bold",
                  color: "#ff6f61",
                  marginBottom: "20px",
                  textTransform: "uppercase",
                  letterSpacing: "1px",
                  fontFamily: "'Roboto', sans-serif",
                }}
            >
              Та нэвтрэх хэсгээр орж анкетаа илгээн үү
            </div>
          </div>
        </Modal>
      </div>
  );
};
export default JobAds;
