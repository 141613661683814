import {
  Divider,
  Table,
  Empty,
  Button,
  Input,
  List,
  Avatar,
  Collapse,
  Row,
  Col,
  Modal,
  Form,
  InputNumber,
  Upload,
  message,
  Spin,
} from "antd";
import { Children, useEffect, useState } from "react";
import {
  HistoryOutlined,
  PlusOutlined,
  ReloadOutlined,
  SendOutlined,
  SmileTwoTone,
  StarOutlined,
} from "@ant-design/icons";
import {
  getService,
  getServicePublic,
  getTokenInfo,
  postService,
  postServicePublic,
} from "../../tools";
import { fileUrl, jobadsUrl, mainUrl } from "../../serviceUrls";
import TextArea from "antd/es/input/TextArea";

const WorkList = (props) => {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [fileCode, setFileCode] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [jobData, setJobData] = useState({});

  useEffect(() => {
    getdata();
  }, []);
  const renderLabel = (data) => {
    return (
        <div>
          <Row>
            <Col span={12} style={{ fontWeight: "bold" }}>
              {data.title}
            </Col>
            <Col span={12} style={{ textAlign: "right" }}>
            <span style={{ color: "#4096ff" }}>
              <HistoryOutlined /> {data.createdDateStr}
            </span>
            </Col>
          </Row>
        </div>
    );
  };
  const renderChildren = (data) => {
    return (
        <div style={{ padding: "15px 20px 15px 20px" }}>
          <div
              dangerouslySetInnerHTML={{
                __html: data.jobAdsDetail?.jobPurposeDescription,
              }}
          ></div>
          <div style={{ fontWeight: "bold" }}>
            {data.jobAdsDocument?.documentTitle}
          </div>
          <div
              dangerouslySetInnerHTML={{
                __html: data.jobAdsDocument?.document,
              }}
          ></div>
          <div style={{ textAlign: "right" }}>
            <Button
                onClick={() => {
                  setJobData(data);
                  modalOpen(data);
                }}
                className="Anketilgeeh"
                size="large"
                icon={<SendOutlined />}
            >
              Анкет илгээх
            </Button>
          </div>
        </div>
    );
  };
  const modalOpen = (data) => {
    let user = getTokenInfo();
    getService(jobadsUrl.checkCv, { id: data.id }).then((res) => {
      if (res) {
        Modal.success({
          content: "Та CV илгээсэн байна.",
        });
      } else {
        setIsModalOpen(true);
        form.setFieldsValue({
          firstName: user["family_name"],
          lastName: user["given_name"],
          email: user["email"],
        });
      }
    });
  };
  const getdata = () => {
    setLoadingTable(true);
    getService(jobadsUrl.getJobsWithDetail)
        .then((res) => {
          let items = [];
          res.forEach((element, index) => {
            let model = { key: null, label: null, children: null };
            model.key = index;
            model.label = renderLabel(element);
            model.children = renderChildren(element);
            items.push(model);
          });
          setData(items);
        })
        .finally(() => {
          setLoadingTable(false);
        });
  };
  const onFinish = () => {
    if (!fileCode) {
      message.warning("CV хоосон байна.");
    }
    let cvModel = {};
    cvModel = form.getFieldsValue();
    cvModel.jobAdsHdrId = jobData.id;
    cvModel.fileCode = fileCode;
    if (cvModel.fileName) {
      delete cvModel.fileName;
    }
    postService(jobadsUrl.sendCV, cvModel).then((res) => {
      if (res.id) {
        message.success(
            "Таны CV амжилттай хүлээн авлаа. Бид таньтай эргэн холбогдох болно."
        );
        setIsModalOpen(false);
      }
    });
  };

  const beforeUpload = (file) => {
    const isLt2M = file.size / 1024 / 1024 < 25;
    if (!isLt2M) {
      message.warninging("Файл 25mb аас дээш хэмжээтэй байна.");
    }
    return isLt2M;
  };
  const upload = async (options) => {
    const { onSuccess, onProgress, file } = options;
    const formData = new FormData();
    formData.append("file", file);
    postService(fileUrl.upload, formData).then((res) => {
      console.log("res:::",res)
      setFileCode(res.code);
      let arrayModel = [];
      arrayModel.push(res);
      setFileList(arrayModel);
    });
    onProgress({ percent: 100 });
    onSuccess("ok");
  };

  return (
      <div>
        <Spin spinning={loadingTable}>
          <Collapse accordion items={data} defaultActiveKey={["1"]} />
        </Spin>
        <Modal
            title={jobData?.title}
            open={isModalOpen}
            onOk={() => {}}
            onCancel={() => {
              setIsModalOpen(false);
            }}
            okText="Анкет илгээх"
            footer={null}
        >
          <Form
              form={form}
              layout="vertical"
              style={{ maxWidth: 600 }}
              onFinish={onFinish}
          >
            <Form.Item name="firstName" label="Овог" rules={[{ required: true }]}>
              <Input disabled maxLength="50" />
            </Form.Item>
            <Form.Item name="lastName" label="Нэр" rules={[{ required: true }]}>
              <Input disabled maxLength="50" />
            </Form.Item>

            <Form.Item
                name="email"
                label="Цахим шуудан"
                rules={[
                  { required: true },
                  {
                    type: "email",
                    message: "Цахим шуудан буруу!",
                  },
                ]}
            >
              <Input disabled maxLength="100" />
            </Form.Item>
            <Form.Item
                name="phoneNo"
                label="Утасны дугаар"
                rules={[{ required: true, message: "Утасны дугаар оруулна уу" }]}
            >
              <InputNumber
                  addonBefore="+976"
                  max={99999999}
                  style={{ width: "100%" }}
              />
            </Form.Item>
            <Form.Item
                name="education"
                label="Боловсролын зэрэг"
                rules={[{ required: true }]}
            >
              <TextArea maxLength="500" rows={4} />
            </Form.Item>

            <Form.Item
                label="CV хавсаргах"
                name="fileName"
                // getValueFromEvent={normFile}
            >
              <Upload
                  accept=".pdf"
                  action="/upload.do"
                  listType="picture-card"
                  multiple={"multiple"}
                  beforeUpload={beforeUpload}
                  customRequest={upload}
                  fileList={(fileList || []).map((r) => {
                    return {
                      id: r.id,
                      uid: r.id,
                      status: "done",
                      name: r.name,
                      url: `/api/itc-gov-service-new/file/view/${r.code}`,
                      type: r.contentType,
                    };
                  })}
              >
                <button style={{ border: 0, background: "none" }} type="button">
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Файл</div>
                </button>
              </Upload>
            </Form.Item>
            <Button
                type="primary"
                htmlType="submit"
                icon={<SendOutlined />}
                style={{ width: "100%" }}
            >
              Анкет илгээх
            </Button>
          </Form>
        </Modal>
        {data.length === 0 ?
            <Empty
                image={null}
                description={
                  <div
                      style={{
                        textAlign: "center",
                        color: "#4d4d4d",
                        padding: "0 40px 40px 40px",
                        borderRadius: "10px",
                        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                        backgroundColor: "#dfeaff",
                        maxWidth: "400px",
                        margin: "0 auto",
                      }}
                  >
                    <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginBottom: "20px",
                        }}
                    >
                      <iframe
                          src="https://lottie.host/embed/753059d2-6301-4402-bd79-dc9e7eff4dc6/b4lpsnPdLS.lottie"
                          style={{
                            width: "150px",
                            height: "150px",
                            border: "none",
                            borderRadius: "10px",
                          }}
                      ></iframe>
                    </div>
                    <div
                        style={{
                          fontSize: "22px",
                          fontWeight: "bold",
                          color: "#ff6f61",
                          marginBottom: "20px",
                          textTransform: "uppercase",
                          letterSpacing: "1px",
                          fontFamily: "'Roboto', sans-serif"
                        }}
                    >
                      Одоогоор зарлагдсан ажлын байр байхгүй байна
                    </div>
                    <Button
                        type="primary"
                        icon={<ReloadOutlined />}
                        onClick={getdata}
                        style={{
                          marginTop: "20px",
                          backgroundColor: "#2265fc",
                          borderColor: "#1f61f6",
                          color: "#fff",
                          borderRadius: "50px",
                          padding: "10px 30px",
                          fontSize: "12px",
                          fontWeight: "bold",
                          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                          textTransform: "uppercase",
                        }}
                    >
                      Дахин оролдох
                    </Button>
                  </div>
                }
            /> : ""
        }
      </div>
  );
};
export default WorkList;
