import * as React from "react";
import {Helmet} from "react-helmet";

export const NewsShare = (props) => {
    return (
        <Helmet>
            <title>{props[0]?.title || "Default title"}</title>
            <meta property="fb:app_id" content="8739992179431678"/>
            <meta property="og:url" content={window.location.href}/>
            <meta property="og:type" content="article"/>
            <meta property="og:title" content={props[0]?.title || "Default title"}/>
            <meta property="og:locale" content="en_US"/>
            <meta
                property="og:image"
                content={process.env.REACT_APP_ITC_GOV_URL + props?.props[0]?.newsImagesList[0]?.url}
            />
            <meta
                property="og:description"
                content={props[0]?.title || "Default description"}
            />
            <meta property="og:site_name" content="ITC GOV"/>


            <meta name="twitter:card" content="summary"/>
            <meta name="twitter:site" content="@nytimesbits"/>
            <meta name="twitter:creator" content="@nickbilton"/>
            <meta property="og:url" content={window.location.href}/>
            <meta property="og:title" content={props[0]?.title || "Default title"}/>
            <meta property="og:description"
                  content={props[0]?.title || "Default description"}
            />
            <meta property="og:image"
                  content={process.env.REACT_APP_ITC_GOV_URL + props?.props[0]?.newsImagesList[0]?.url}
            />
        </Helmet>
    )
}

export default NewsShare;