import React, {useContext, useState} from "react";
import {Button, Col, Layout, Row, Space, Switch, theme, Image, Drawer, Menu} from "antd";
import {MenuUnfoldOutlined, MoonFilled, SunFilled} from "@ant-design/icons";
import mainContext from "../mainContext";
import {BrowserRouter, Link, useNavigate, useRoutes} from "react-router-dom";
import keycloak from "../../keycloack";
import {FormattedMessage} from "react-intl";

const { Header } = Layout;

export default function LandingMainHeader({locale, setLocale}) {
  const [open, setOpen] = useState(false);
  const [selectKeys, setSelectKeys] = useState("0");
  const hdrData2 = [
    { label: locale === 'mn' ? "Нүүр хуудас" : "Home page", key: "/", router: "/" },
    { label: locale === 'mn' ? "Бидний тухай" : "About us", key: "/about", router: "/about" },
    { label: locale === 'mn' ? "Төслүүд" : "Overview  of projects", key: "/project", router: "/project" },
    { label: locale === 'mn' ? "Мэдээ мэдээлэл" : "News", key: "/news", router: "/news" },
    { label: locale === 'mn' ? "Ил тод байдал" : "Transparency", key: "/publicity",  router: "/publicity",},
    { label: locale === 'mn' ? "Хууль эрх зүй" : "Law & legislation", key: "/law", router: "/law" },
    { label: locale === 'mn' ? "Шилэн данс" : "Transparency of account", key: "https://shilendans.gov.mn/organization/25056", router: "https://shilendans.gov.mn/organization/25056" },
    { label: locale === 'mn' ? "Нэвтрэх" : "Login", key: "/auth/login", router: "/auth/login" },
    //{ label: "Гарах", key: "/auth/logout", router: "/auth/logout" },
  ];
  const navigate = useNavigate();
  const changeLocale = () => {
    const to = locale === "en" ? "mn" : "en";
    localStorage.setItem("language_local", to)
    setLocale(to);
  };
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const menuFunc = (params, kk) => {
    navigate(params.key);
    setSelectKeys(params.key);
    setOpen(false);
  };
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const { userTheme, setUserTheme } = useContext(mainContext);
  return (
    <Header
      style={{
        padding: 0,
        background: colorBgContainer,
        position: "sticky",
        top: 0,
        zIndex: 1,
        height: 95,
      }}
    >
      <section>
        <div className="bg-brand-50 z-10000 w-full text-white text-base font-normal px-6 max-sm:pl-3 border-b-[6px] border-brand-100">
          <div className="py-4 flex justify-between items-center">
            <Link
                to={"/"}
                className="text-3xl uppercase cursor-pointer max-sm:h-[45px]"
            >
              {locale === "mn" ?
                  <img
                      className="h-full w-60 "
                      alt="ITC GOV"
                      src={"/images/logo/new-LogoWhite.png"}
                      style={{maxHeight: "60px"}}
                  />
                  :
                  <img
                      className="h-full w-60 "
                      alt="ITC GOV"
                      src={"/images/logo/new-LogoEng.png"}
                      style={{maxHeight: "60px"}}
                  />}
            </Link>
            <div className="flex items-center max-lg:hidden">
              <Link
                  to={"/about"}
                  className="p-4 max-xl:p-1 border border-brand-50 bg-brand-50 hover:border-white mx-1"
              >
                <FormattedMessage id="header_about_us"/>
              </Link>
              <Link
                  to={"/project"}
                  className="p-4 max-xl:p-1 border border-brand-50 bg-brand-50 hover:border-white mx-1"
              >
                <FormattedMessage id="header_projects"/>
              </Link>
              <Link
                  to={"/news"}
                  className="p-4 max-xl:p-1 border border-brand-50 bg-brand-50 hover:border-white mx-1"
              >
                <FormattedMessage id="header_news"/>
              </Link>
              <Link
                  to={"/publicity"}
                  className="p-4 max-xl:p-1 border border-brand-50 bg-brand-50 hover:border-white mx-1"
              >
                <FormattedMessage id="header_transparency"/>
              </Link>
              <Link
                  to={"/law"}
                  className="p-4 max-xl:p-1 border border-brand-50 bg-brand-50 hover:border-white mx-1"
              >
                <FormattedMessage id="header_law"/>
              </Link>
              <Link
                  to={"https://shilendans.gov.mn/organization/25056"}
                  className="p-4 max-xl:p-1 border border-brand-50 bg-brand-50 hover:border-white mx-1"
              >
                <FormattedMessage id="header_account"/>
              </Link>
              <Link
                  className="p-4 max-xl:p-1 border border-brand-50 bg-brand-50 hover:border-white mx-1"
                  onClick={() => {
                    keycloak.login();
                  }}
              >
                <FormattedMessage id="home_login"/>
              </Link>
              <span className="cursor-pointer" onClick={() => {
                changeLocale()
              }}>
                {locale === "mn" ?
                    <svg width="30px" height="30px" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg"
                         xmlnsXlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img"
                         className="iconify iconify--twemoji" preserveAspectRatio="xMidYMid meet">
                      <path fill="#B22334"
                            d="M35.445 7C34.752 5.809 33.477 5 32 5H18v2h17.445zM0 25h36v2H0zm18-8h18v2H18zm0-4h18v2H18zM0 21h36v2H0zm4 10h28c1.477 0 2.752-.809 3.445-2H.555c.693 1.191 1.968 2 3.445 2zM18 9h18v2H18z"/>
                      <path fill="#EEE"
                            d="M.068 27.679c.017.093.036.186.059.277c.026.101.058.198.092.296c.089.259.197.509.333.743L.555 29h34.89l.002-.004a4.22 4.22 0 0 0 .332-.741a3.75 3.75 0 0 0 .152-.576c.041-.22.069-.446.069-.679H0c0 .233.028.458.068.679zM0 23h36v2H0zm0-4v2h36v-2H18zm18-4h18v2H18zm0-4h18v2H18zM0 9zm.555-2l-.003.005L.555 7zM.128 8.044c.025-.102.06-.199.092-.297a3.78 3.78 0 0 0-.092.297zM18 9h18c0-.233-.028-.459-.069-.68a3.606 3.606 0 0 0-.153-.576A4.21 4.21 0 0 0 35.445 7H18v2z"/>
                      <path fill="#3C3B6E" d="M18 5H4a4 4 0 0 0-4 4v10h18V5z"/>
                      <path fill="#FFF"
                            d="M2.001 7.726l.618.449l-.236.725L3 8.452l.618.448l-.236-.725L4 7.726h-.764L3 7l-.235.726zm2 2l.618.449l-.236.725l.617-.448l.618.448l-.236-.725L6 9.726h-.764L5 9l-.235.726zm4 0l.618.449l-.236.725l.617-.448l.618.448l-.236-.725l.618-.449h-.764L9 9l-.235.726zm4 0l.618.449l-.236.725l.617-.448l.618.448l-.236-.725l.618-.449h-.764L13 9l-.235.726zm-8 4l.618.449l-.236.725l.617-.448l.618.448l-.236-.725l.618-.449h-.764L5 13l-.235.726zm4 0l.618.449l-.236.725l.617-.448l.618.448l-.236-.725l.618-.449h-.764L9 13l-.235.726zm4 0l.618.449l-.236.725l.617-.448l.618.448l-.236-.725l.618-.449h-.764L13 13l-.235.726zm-6-6l.618.449l-.236.725L7 8.452l.618.448l-.236-.725L8 7.726h-.764L7 7l-.235.726zm4 0l.618.449l-.236.725l.617-.448l.618.448l-.236-.725l.618-.449h-.764L11 7l-.235.726zm4 0l.618.449l-.236.725l.617-.448l.618.448l-.236-.725l.618-.449h-.764L15 7l-.235.726zm-12 4l.618.449l-.236.725l.617-.448l.618.448l-.236-.725l.618-.449h-.764L3 11l-.235.726zM6.383 12.9L7 12.452l.618.448l-.236-.725l.618-.449h-.764L7 11l-.235.726h-.764l.618.449zm3.618-1.174l.618.449l-.236.725l.617-.448l.618.448l-.236-.725l.618-.449h-.764L11 11l-.235.726zm4 0l.618.449l-.236.725l.617-.448l.618.448l-.236-.725l.618-.449h-.764L15 11l-.235.726zm-12 4l.618.449l-.236.725l.617-.448l.618.448l-.236-.725l.618-.449h-.764L3 15l-.235.726zM6.383 16.9L7 16.452l.618.448l-.236-.725l.618-.449h-.764L7 15l-.235.726h-.764l.618.449zm3.618-1.174l.618.449l-.236.725l.617-.448l.618.448l-.236-.725l.618-.449h-.764L11 15l-.235.726zm4 0l.618.449l-.236.725l.617-.448l.618.448l-.236-.725l.618-.449h-.764L15 15l-.235.726z"/>
                    </svg> : <svg width="30px" height="30px" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img"
                                  className="iconify iconify--twemoji" preserveAspectRatio="xMidYMid meet">
                      <path fill="#C4272F"
                            d="M36 27a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V9a4 4 0 0 1 4-4h28a4 4 0 0 1 4 4v18z">
                      </path>
                      <path fill="#005197" d="M12 5h12v26H12z">
                      </path>
                      <g fill="#F9CF01">
                        <path
                            d="M1.286 16.429h1.571V29H1.286zm2.395 2.357H8.32v.786H3.681zm5.462-2.357h1.571V29H9.143zm-5.462 9.427H8.32v.786H3.681zm.087-9.427h4.464L6 17.989zm-.087 11.198h4.638L6 29.249zm3.89-15.913a1.57 1.57 0 1 1-3.141 0a1.57 1.57 0 0 1 3.141 0zm-.785-2.946c0 .759-.352.982-.786.982s-.786-.223-.786-.982C5.214 8.008 5.754 7 6 7s.786 1.008.786 1.768z">
                        </path>
                        <path
                            d="M6 14.404c-1.303 0-2.438-.593-3.084-1.477a3.137 3.137 0 0 0 6.168.001c-.646.883-1.781 1.476-3.084 1.476zm2.569 8.31a2.558 2.558 0 0 0-1.649-2.39c.299.257.58.447.58 1.062c0 .973-.578 1.496-1.5 1.496v.009a1.198 1.198 0 0 0-1.158 1.193c0 .645.516 1.167 1.158 1.191v.008c.01 0 .018-.003.028-.003c.005 0 .009.003.014.003c.015 0 .027-.008.042-.008a2.563 2.563 0 0 0 2.485-2.561zm-2.94 1.37c0-.229.183-.413.413-.413a.413.413 0 1 1-.413.413z">
                        </path>
                        <path
                            d="M6 20.152v-.006l-.021.002l-.01-.002c-.011 0-.02.006-.031.006a2.564 2.564 0 0 0-2.507 2.562c0 1.096.687 2.023 1.649 2.391c-.299-.257-.557-.448-.557-.999c0-.98.524-1.522 1.43-1.522l.002-.018c.005 0 .009.003.014.003c.668 0 1.211-.546 1.211-1.212c0-.659-.527-1.188-1.18-1.205zm-.031 1.62a.417.417 0 1 1 0-.835a.417.417 0 0 1 0 .835z">
                        </path>
                      </g>
                    </svg>
                }
              </span>
            </div>
            <div className="lg:hidden flex items-center">
              <button onClick={showDrawer}>
                <MenuUnfoldOutlined className="text-3xl hover:text-brand-150"/>
              </button>
              <Drawer
                  placement="left"
                  onClose={onClose}
                  open={open}
                  extra={
                    <span className="cursor-pointer" onClick={() => {
                      changeLocale()
                    }}>
                        {locale === "mn" ?
                            <svg width="30px" height="30px" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg"
                                 xmlnsXlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img"
                                 className="iconify iconify--twemoji" preserveAspectRatio="xMidYMid meet">
                              <path fill="#B22334"
                                    d="M35.445 7C34.752 5.809 33.477 5 32 5H18v2h17.445zM0 25h36v2H0zm18-8h18v2H18zm0-4h18v2H18zM0 21h36v2H0zm4 10h28c1.477 0 2.752-.809 3.445-2H.555c.693 1.191 1.968 2 3.445 2zM18 9h18v2H18z"/>
                              <path fill="#EEE"
                                    d="M.068 27.679c.017.093.036.186.059.277c.026.101.058.198.092.296c.089.259.197.509.333.743L.555 29h34.89l.002-.004a4.22 4.22 0 0 0 .332-.741a3.75 3.75 0 0 0 .152-.576c.041-.22.069-.446.069-.679H0c0 .233.028.458.068.679zM0 23h36v2H0zm0-4v2h36v-2H18zm18-4h18v2H18zm0-4h18v2H18zM0 9zm.555-2l-.003.005L.555 7zM.128 8.044c.025-.102.06-.199.092-.297a3.78 3.78 0 0 0-.092.297zM18 9h18c0-.233-.028-.459-.069-.68a3.606 3.606 0 0 0-.153-.576A4.21 4.21 0 0 0 35.445 7H18v2z"/>
                              <path fill="#3C3B6E" d="M18 5H4a4 4 0 0 0-4 4v10h18V5z"/>
                              <path fill="#FFF"
                                    d="M2.001 7.726l.618.449l-.236.725L3 8.452l.618.448l-.236-.725L4 7.726h-.764L3 7l-.235.726zm2 2l.618.449l-.236.725l.617-.448l.618.448l-.236-.725L6 9.726h-.764L5 9l-.235.726zm4 0l.618.449l-.236.725l.617-.448l.618.448l-.236-.725l.618-.449h-.764L9 9l-.235.726zm4 0l.618.449l-.236.725l.617-.448l.618.448l-.236-.725l.618-.449h-.764L13 9l-.235.726zm-8 4l.618.449l-.236.725l.617-.448l.618.448l-.236-.725l.618-.449h-.764L5 13l-.235.726zm4 0l.618.449l-.236.725l.617-.448l.618.448l-.236-.725l.618-.449h-.764L9 13l-.235.726zm4 0l.618.449l-.236.725l.617-.448l.618.448l-.236-.725l.618-.449h-.764L13 13l-.235.726zm-6-6l.618.449l-.236.725L7 8.452l.618.448l-.236-.725L8 7.726h-.764L7 7l-.235.726zm4 0l.618.449l-.236.725l.617-.448l.618.448l-.236-.725l.618-.449h-.764L11 7l-.235.726zm4 0l.618.449l-.236.725l.617-.448l.618.448l-.236-.725l.618-.449h-.764L15 7l-.235.726zm-12 4l.618.449l-.236.725l.617-.448l.618.448l-.236-.725l.618-.449h-.764L3 11l-.235.726zM6.383 12.9L7 12.452l.618.448l-.236-.725l.618-.449h-.764L7 11l-.235.726h-.764l.618.449zm3.618-1.174l.618.449l-.236.725l.617-.448l.618.448l-.236-.725l.618-.449h-.764L11 11l-.235.726zm4 0l.618.449l-.236.725l.617-.448l.618.448l-.236-.725l.618-.449h-.764L15 11l-.235.726zm-12 4l.618.449l-.236.725l.617-.448l.618.448l-.236-.725l.618-.449h-.764L3 15l-.235.726zM6.383 16.9L7 16.452l.618.448l-.236-.725l.618-.449h-.764L7 15l-.235.726h-.764l.618.449zm3.618-1.174l.618.449l-.236.725l.617-.448l.618.448l-.236-.725l.618-.449h-.764L11 15l-.235.726zm4 0l.618.449l-.236.725l.617-.448l.618.448l-.236-.725l.618-.449h-.764L15 15l-.235.726z"/>
                            </svg> : <svg width="30px" height="30px" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg"
                                          xmlnsXlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img"
                                          className="iconify iconify--twemoji" preserveAspectRatio="xMidYMid meet">
                              <path fill="#C4272F"
                                    d="M36 27a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V9a4 4 0 0 1 4-4h28a4 4 0 0 1 4 4v18z">
                              </path>
                              <path fill="#005197" d="M12 5h12v26H12z">
                              </path>
                              <g fill="#F9CF01">
                                <path
                                    d="M1.286 16.429h1.571V29H1.286zm2.395 2.357H8.32v.786H3.681zm5.462-2.357h1.571V29H9.143zm-5.462 9.427H8.32v.786H3.681zm.087-9.427h4.464L6 17.989zm-.087 11.198h4.638L6 29.249zm3.89-15.913a1.57 1.57 0 1 1-3.141 0a1.57 1.57 0 0 1 3.141 0zm-.785-2.946c0 .759-.352.982-.786.982s-.786-.223-.786-.982C5.214 8.008 5.754 7 6 7s.786 1.008.786 1.768z">
                                </path>
                                <path
                                    d="M6 14.404c-1.303 0-2.438-.593-3.084-1.477a3.137 3.137 0 0 0 6.168.001c-.646.883-1.781 1.476-3.084 1.476zm2.569 8.31a2.558 2.558 0 0 0-1.649-2.39c.299.257.58.447.58 1.062c0 .973-.578 1.496-1.5 1.496v.009a1.198 1.198 0 0 0-1.158 1.193c0 .645.516 1.167 1.158 1.191v.008c.01 0 .018-.003.028-.003c.005 0 .009.003.014.003c.015 0 .027-.008.042-.008a2.563 2.563 0 0 0 2.485-2.561zm-2.94 1.37c0-.229.183-.413.413-.413a.413.413 0 1 1-.413.413z">
                                </path>
                                <path
                                    d="M6 20.152v-.006l-.021.002l-.01-.002c-.011 0-.02.006-.031.006a2.564 2.564 0 0 0-2.507 2.562c0 1.096.687 2.023 1.649 2.391c-.299-.257-.557-.448-.557-.999c0-.98.524-1.522 1.43-1.522l.002-.018c.005 0 .009.003.014.003c.668 0 1.211-.546 1.211-1.212c0-.659-.527-1.188-1.18-1.205zm-.031 1.62a.417.417 0 1 1 0-.835a.417.417 0 0 1 0 .835z">
                                </path>
                              </g>
                            </svg>
                        }
                    </span>
                  }
                  width={310}
              >

                <Menu
                    mode="inline"
                    selectedKeys={[selectKeys]}
                    style={{width: 256, border: "none"}}
                    items={hdrData2}
                    onClick={menuFunc}
                />
              </Drawer>
            </div>
          </div>
        </div>
      </section>
    </Header>
  );
}
