const en = {
    "main_intro_1":"Information Technology Center of Finance",
    "main_intro_2":"A state-owned industry",
    "header_about_us":"About us",
    "header_projects":"Overview of projects",
    "header_news":"News",
    "header_transparency":"Transparency",
    "header_law":"Law & legislation",
    "header_account":"Transparency of account",

    "vision_contact":"Contact Us",
    "vision_feedback":"Feedback",
    "vision_call":"Call to: +976110 about corruption",
    "footer_content":"To be a leader in Information technology of Mongolia's state financial sector",

    "about_us_vision":"Vision",
    "about_us_vision_content":"To be a leader in Information technology of Mongolia's state financial sector.",

    "intro":"Introduction",
    "about_us_intro":"The Information Technology Center of finance is a state-owned industry responsible for digitalizing the operations of financial, tax, and customs organizations. It develops and implements information systems, ensures the continuous operation of these systems, provides professional and methodological advice on financial information technology, and manages the creation of a unified financial information database. We are involved in improving information technology coordination between Mongolian ministries.",
    "about_us_mission":"Mission",
    "about_us_mission_1":"Conducting software development",
    "about_us_mission_2":"Adopting advanced IT technologies for system development and implementation",
    "about_us_mission_3":"Creating unified financial information database",
    "about_us_mission_4":"Monitoring and ensuring security of the integrated financial information system",

    "about_main_functions":"The main functions of the organization",
    "about_main_functions_development":"Software development",
    "about_main_functions_development_content":"To develop information systems and software based on the orders and policy directives of financial, tax, and customs organizations, ensure their integration, and create opportunities for information sharing",
    "about_main_functions_collaboration":"Collaboration",
    "about_main_functions_collaboration_content":"According to official tasks of the Government's authority collaborate in the digitalization of activities and providing IT service",
    "about_main_functions_security":"Information Security",
    "about_main_functions_security_content":"Ensure the information security on financial system of taxation, customs and Ministry of finance",
    "about_main_functions_database":"Database",
    "about_main_functions_database_content":"Support for the operation of the database of financial, tax and customs institutions, creating a unified database, processing and analyzing data",
    "about_main_functions_innovation":"Innovation",
    "about_main_functions_innovation_content":"Deploy the developed information system, ensure continuous operation, and introduce technological innovations into operations",
    "about_main_functions_research":"Research and analysis",
    "about_main_functions_research_content":"Research and analysis on the potential for digitalization of finance, tax, and customs operations",
    "about_main_functions_coherence":"System coherence",
    "about_main_functions_coherence_content":"Within the framework of the policy pursued by the Government of Mongolia, improve the cooperation of information technology among government institutions and increase the efficiency of e-governance",


    "org_structure":"Organizational structure",
    "about_us_org_administration":"Administration department",
    "about_us_org_finance":"E-Finance Information Department",
    "about_us_org_system":"System Planning and Statistics Department",
    "about_us_org_system_dev":"System Development Department",
    "about_us_org_system_implementation":"System Implementation Department",
    "about_us_org_system_integration":"System Implementation Department",
    "employees":"Employees",

    "more_view":"More",
    "project_content":"Responsible for the oversight and software development of the integrated information system for financial, tax, and customs organizations. Through this, it aims to increase budget revenue generation, enhance the tax base, improve budget expenditure control, automate export and import operations, and provide information technology support to other government organizations, thereby implementing extensive work in the state digital transition.",

    "iso_header":"ISO/IEC 27001:2022 International Standard",
    "iso_content":"The first government organization to adopted the Information Security Management System ISO/IEC 27001:2022 standard",
    "iso_main_header":"An organization compliant with the international standard for Information Security Management Systems",
    "iso_main_content_1":"We are committed to establishing, implementing, maintaining, and continuously improving an Information Security Management System (ISMS) as part of our mandate to introduce advanced technology into the operations of customs, tax, and financial departments by overseeing the integrated information system and software development.",
    "iso_main_content_2":"We place special emphasis on enhancing the governance and capability levels of the organization to align with global standards and are leading in this area within the sector. Also, we successfully implemented the Information Security Management System in 2021, encompassing the data and physical assets of the systems it oversees. It underwent an audit for certification by internationally accredited organizations, Certiva Limited and LMS Certification Pvt Ltd from the United Kingdom and Northern Ireland, and became the first government organization to meet the ISO/IEC 27001:2013 standard.",
    "iso_main_content_3":"  We are continuously improving our ISMS and have successfully transitioned to the new version of the standard, ISO/IEC 27001:2022, certified certificate on June 24, 2024.",
    "home_breadCrumb":"Home page",

    "budget_revenue":"Budget Revenue",
    "budget_revenue_content":"Budget revenue and its structure and composition are also manifestations of economic relations related to the allocation and redistribution of national income.",
    "budget_expenditure":"Budget Expenditure",
    "budget_expenditure_content":"The manifestation of economic relations arising from the expenditure of funds centralized in the budget for the purpose of fulfilling the government's responsibilities is reflected in budget expenditure and financing.",
    "budget_control":"Budget control",
    "budget_control_content":"Budget control is a process that is implemented based on the budget's objectives and principles, assessing whether it has achieved its goals.",


    "home":"Home page",
    "home_req":"Pos API 3.0 request",
    "home_transparency":"Transparency",
    "home_transparency_activity":"Activity",
    "home_transparency_activity_plan":"Plans",
    "home_transparency_activity_report":"Feedback report",
    "home_transparency_human":"Human Resource",
    "home_transparency_human_emp":"Employees",
    "home_transparency_human_career":"Careers",
    "home_transparency_finance":"Financial information",
    "home_transparency_finance_procure":"Procurement",
    "home_transparency_finance_audit":"Audit reports",
    "home_address":"Address:",
    "home_address_item":"16th Floor, NM Tower, Orgil Stadium/17011/,<br/> Mahatma Gandhi Street 31/1, 15th Khoroo,<br/> Khan-Uul District, Ulaanbaatar, Mongolia",
    "home_contact":"Contacts",
    "home_contact_1":"Office manager",
    "home_contact_2":"Human resource",
    "home_contact_3":"System monitoring",
    "home_contact_email":"Email :",
    "home_contact_email_item":"info@itc.gov.mn",
    "home_time":"Timetable :",
    "home_time_1":"Monday-Friday",
    "home_time_2":"Saturday N/A, Sunday N/A",
    "formatted_iso_image":"/images/human/butets-01.jpg",
    "formatted_iso_image_2":"/images/logo/new-LogoEng.png",
    "home_login":"Login",

    "law":"Law",
    "rules":"Rules, Regulations, Resolutions"
}

export default en;