import {
  ClockCircleOutlined,
  EyeOutlined,
  FileTextOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";
import {
  Carousel,
  ConfigProvider,
  Empty,
  Image as AntImage,
  Modal,
  Space,
  Spin,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import SimpleMotion from "../components/assist/simpleMotion/SimpleMotion";
import Title from "./Title";
import { Link, useParams } from "react-router-dom";
import Arrow from "../components/assist/arrow/Arrow";
import News from "./News";
import { getServicePublic, postServicePublic } from "../tools";
import { newsUrl } from "../serviceUrls";
import RouterContext from "./routerContext";
import {FacebookIcon, FacebookShareButton, TwitterIcon, TwitterShareButton} from "react-share";
import NewsShare from "./NewsShare";

const NewsId = () => {
  const [winWidth, setWinWidth] = useState(null);
  const [imgHeight, setImgHeight] = useState(null);
  const [imgWidth, setImgWidth] = useState(null);
  const [height, setHeight] = useState(null);
  const [newsDetail, setNewsDetail] = useState({});
  const [specialNews, setSpecialNews] = useState({});
  const [fileViewDialog, setFileViewDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  const [fileUrl, setFileUrl] = useState(null);
  const param = useParams();
  const { setRouterCont } = useContext(RouterContext);

  useEffect(() => {
    setLoading(true);
    getProject();
    window.addEventListener("resize", getProject);
    getServicePublic(newsUrl.getNewsIdDoc, { id: param.id })
      .then((res) => {
        console.log({res})
        setNewsDetail(res);
        setRouterCont({
          id: param.id,
          title: res[0].title,
        });
      })
      .finally(() => {
        setLoading(false);
      });
    postServicePublic(newsUrl.getSpecialNews).then((res) => {
      setSpecialNews(res);
    });
  }, [height, winWidth, param.id]);

  const setDialog = (value, url) => {
    setFileViewDialog(value);
    setFileUrl(url ? url : null);
  };

  const getProject = () => {
    setWinWidth(window.innerWidth);
    setImgWidth(
      window.innerWidth > 768
        ? Math.round((window.innerWidth / 3) * 2)
        : window.innerWidth
    );
    setImgHeight(
      window.innerWidth > 768
        ? Math.round((window.innerWidth / 8) * 3)
        : Math.round((window.innerWidth / 16) * 9)
    );
  };

  return (
    <SimpleMotion>
        <Spin spinning={loading}>
          <div className="mt-4 max-w-[1280px] mx-auto text-brand-50">
            {newsDetail ? (
                <>
                  <div className="flex grid-2 gap-4 max-md:flex-col max-sm:mb-4">
                    <div
                        id={"first"}
                        className="w-2/3 max-md:w-full bg-white h-fit"
                    >
                      <div className="max-md:h-full">
                        {newsDetail[0]?.newsImagesList === null ||
                        newsDetail[0]?.newsImagesList.length === 0 ? (
                            <AntImage
                                style={{ objectFit: "cover" }}
                                className="max-md:w-full max-md:h-full"
                                src={"/api/itc-gov-service-new/file/view/" + newsDetail[0].image}
                                alt="ITC GOV"
                                width={winWidth > 1280 ? 853 : imgWidth}
                                height={winWidth > 1280 ? 413 : imgHeight}
                                preview={true}
                                fallback="/images/noImage.png"
                            />
                        ) : (
                            <ConfigProvider
                                theme={{
                                  components: {
                                    Carousel: {
                                      arrowSize: 40,
                                      arrowOffset: 16,
                                      dotWidth: 16,
                                      dotHeight: 3,
                                      dotOffset: 20,
                                    },
                                  },
                                }}
                            >
                              <Carousel
                                  autoplay
                                  arrows
                                  arrowSize={40}
                                  infinite={true}
                              >
                                {newsDetail[0]?.newsImagesList.map((data, i) => (
                                    <div key={i}>
                                      <AntImage
                                          style={{ objectFit: "cover" }}
                                          src={data.url}
                                          alt="ITC GOV"
                                          width={winWidth > 1280 ? 853 : imgWidth}
                                          height={winWidth > 1280 ? 413 : imgHeight}
                                          preview={true}
                                          fallback="/images/noImage.png"
                                      />
                                    </div>
                                ))}
                              </Carousel>
                            </ConfigProvider>
                        )}
                      </div>

                      <div className="bg-white">
                        <div className="flex items-start justify-between max-sm:flex-col">
                          <section
                              style={{ background: "#fff", width: "100%" }}
                              //   className={`${inter.className}`}
                          >
                            <div className="py-6 font-black text-3xl text-brand-50 flex items-start">
                              <div className="w-3 h-7 bg-brand-50"></div>
                              <div
                                  className={
                                    "test-size max-md:text-2xl leading-7 pl-6 w-11/12"
                                  }
                              >
                                {newsDetail[0]?.title}
                              </div>
                            </div>
                          </section>
                          <div
                              className={
                                "max-sm:flex max-sm:justify-end max-sm:w-full max-sm:relative max-sm:-top-3"
                              }
                          >
                            <div className="bg-brand-50 px-3 py-2 my-6 max-sm:my-0 text-gray-100 flex items-center text-sm w-36">
                              <div className="flex items-center pr-2">
                                <ClockCircleOutlined />
                              </div>
                              <div className="flex items-center">
                                {newsDetail[0]?.createdDateStr?.substring(0, 10)}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="text-justify min-h-[229px] max-h-full bg-white">
                          <div
                              // className={`${inter.className}`}
                              style={{
                                backgroundColor: "#fff",
                                paddingLeft: "36px",
                                paddingRight: "36px",
                              }}
                          >
                            <div
                                className={`name text-wrap break-words text-brand-50 pb-8 `}
                                dangerouslySetInnerHTML={{
                                  __html: newsDetail[0]?.description,
                                }}
                            ></div>
                            <div
                                className={
                                  "flex w-full h-full pt-6 pb-3 mb-6 overflow-auto"
                                }
                            >
                              <div className={"flex gap-2"}>
                                {newsDetail[0]?.newsDocumentList ? (
                                    newsDetail[0]?.newsDocumentList.map((e, i) =>
                                        e.isVideo === 0 ? (
                                            e.fileStoreId.match(/\.pdf/g) ? (
                                                <div
                                                    className={
                                                      "border-[1px] border-gray-300 w-[158px] h-[158px] group hover:bg-black/50 duration-75 relative flex justify-center items-center bg-gray-200"
                                                    }
                                                    onClick={() => setDialog(true, e.url)}
                                                >
                                                  <div
                                                      className={
                                                        "text-[100px] justify-self-center self-center"
                                                      }
                                                      align="center"
                                                  >
                                                    <FileTextOutlined/>
                                                  </div>
                                                  <div
                                                      className={
                                                        "absolute z-10 text-white invisible group-hover:visible text-sm align-middle justify-self-center flex justify-center items-center"
                                                      }
                                                  >
                                                    <EyeOutlined/> Preview
                                                  </div>
                                                </div>
                                            ) : (
                                                <AntImage
                                                    style={{objectFit: "cover"}}
                                                    className={"border-[1px] border-gray-300"}
                                                    src={e.url}
                                                    alt="ITC GOV"
                                                    width={158}
                                                    height={158}
                                                    preview={true}
                                                    fallback="/images/noImage.png"
                                                />
                                            )
                                        ) : (
                                            <AntImage
                                                style={{objectFit: "cover"}}
                                                className={"border-[1px] border-gray-300"}
                                                src={
                                                    "https://i.ytimg.com/vi/" +
                                                    e.fileStoreId +
                                                    "/mqdefault.jpg"
                                                }
                                                alt="ITC GOV"
                                                width={158}
                                                height={158}
                                                preview={{
                                                  destroyOnClose: true,
                                                  imageRender: () => (
                                                      <div
                                                          className={"flex justify-center py-5"}
                                                      >
                                                        <iframe
                                                            width={`${
                                                                winWidth > 640 ? "1280" : "640"
                                                            }`}
                                                            height={`${
                                                                winWidth > 640 ? "720" : "480"
                                                            }`}
                                                            className={"w-full aspect-video"}
                                                            src={`https://www.youtube.com/embed${
                                                                e.url.split("https://youtu.be")[1]
                                                            }?autoplay=1`}
                                                            title="YouTube video player"
                                                            frameBorder="0"
                                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                            referrerPolicy="strict-origin-when-cross-origin"
                                                            allowFullScreen
                                                        ></iframe>
                                                      </div>
                                                  ),
                                                  toolbarRender: () => null,
                                                  maskClassName: "customize-mask",
                                                  mask: (
                                                      <Space
                                                          direction="vertical"
                                                          align="center"
                                                      >
                                                        <PlayCircleOutlined/>
                                                      </Space>
                                                  ),
                                                }}
                                                // onClick={setVideoDialog(true)}
                                            />
                                        )
                                    )
                                ) : (
                                    <></>
                                )}
                              </div>
                            </div>

                            <div className="flex justify-end space-x-1.5 my-4">
                              <NewsShare props={newsDetail} />

                              <FacebookShareButton
                                  url={window.location.href}
                                  title="Хуваалцах"
                              >
                                <FacebookIcon size={32} round/>
                              </FacebookShareButton>

                              <TwitterShareButton
                                  url={window.location.href}
                                  title="Хуваалцах"
                              >
                                <TwitterIcon size={32} round/>
                              </TwitterShareButton>
                            </div>
                            <Modal
                                open={fileViewDialog}
                                footer={null}
                                onCancel={() => setDialog(false, null)}
                                width={1280}
                            >
                              <iframe
                                  className="pdf"
                                  src={fileUrl}
                                  width="100%"
                                  height="800"
                              ></iframe>
                            </Modal>
                            {/* Certificate haruulj bgaa heseg */}
                            {param === 23 ? (
                                <div
                                    className={"flex w-full h-full py-6 overflow-auto"}
                                >
                                  <div className={"flex gap-2"}>
                                    <AntImage
                                        style={{objectFit: "cover"}}
                                        className={"border-[1px] border-gray-300"}
                                        src={"/images/sertificate/sert2024.png"}
                                        alt="ITC GOV"
                                        width={158}
                                        height={158}
                                        preview={true}
                                        fallback="/images/noImage.png"
                                    />
                                    <AntImage
                                        style={{objectFit: "cover"}}
                                        className={"border-[1px] border-gray-300"}
                                        src={"/images/sertificate/sert1.png"}
                                        alt="ITC GOV"
                                        width={158}
                                        height={158}
                                        preview={true}
                                        fallback="/images/noImage.png"
                                    />
                                    <AntImage
                                        style={{objectFit: "cover"}}
                                        className={"border-[1px] border-gray-300"}
                                        src={"/images/sertificate/sert2.png"}
                                        alt="ITC GOV"
                                        width={158}
                                        height={158}
                                        preview={true}
                                        fallback="/images/noImage.png"
                                    />
                                  </div>
                                </div>
                            ) : (
                                <></>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Онцлох мэдээний хэсэг */}
                    <div
                        className={`w-1/3 max-md:w-full flex-1 bg-brand-50 relative md:mb-3 max-md:pb-3 max-sm:pb-6 text-ellipsis overflow-hidden md:border-b-[12px] md:border-brand-50`}
                    >
                      {specialNews ? (
                          <div
                              style={{ height: winWidth > 768 ? height - 12 : "auto" }}
                          >
                            <Link
                                to={`/news/${specialNews.id}`}
                                className="relative bg-brand-50 group"
                            >
                              <div className="flex flex-col justify-between bg-brand-50">
                                <Title
                                    name={"Онцлох мэдээ"}
                                    size={"small"}
                                    color={"brand-50"}
                                />
                                {/* <Title name={props.newsType2[0].title} size="small" /> */}
                                <div className="text-base font-semibold text-white px-9 pb-6 group-hover:translate-x-4 duration-500 group-hover:underline group-hover:underline-offset-2 flex items-center">
                                  <div>{specialNews.title}</div>
                                  <div className={"group-hover:visible invisible"}>
                                    <Arrow name={"white"} />
                                  </div>
                                </div>
                              </div>
                              <div className="relative px-9 h-[155px] max-md:h-[140px] bg-brand-50">
                                <AntImage
                                    preview={false}
                                    style={{ objectFit: "cover" }}
                                    height={"100%"}
                                    width={"100%"}
                                    //className="max-md:w-full max-md:h-full"
                                    src={`/api/itc-gov-service-new/file/view/${specialNews.image}`}
                                    alt="ITC GOV"
                                    fallback="/images/noImage.png"
                                />
                              </div>
                              <div>
                                <div
                                    className="name text-white break-words bg-brand-50 text-justify px-9 py-6 pb-16 text-sm leading-5 z-0 relative text-ellipsis overflow-hidden max-md:h-[140px]"
                                    dangerouslySetInnerHTML={{
                                      __html: specialNews.description,
                                    }}
                                ></div>
                              </div>
                            </Link>
                          </div>
                      ) : (
                          <Empty/>
                      )}
                    </div>
                  </div>
                  {/* Мэдээний жягсаалт */}
                  <News isHomePage={false} />
                </>
            ) : (
                <Empty />
            )}
          </div>
        </Spin>
    </SimpleMotion>
  );
};
export default NewsId;
